import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { styled } from "@mui/material";

export const Button = styled(
  ({ size = "small", ...props }: LoadingButtonProps) => (
    <LoadingButton size={size} {...props} />
  ),
)({
  textTransform: "none",
  fontWeight: "bold",
  flex: "none",
  whiteSpace: "nowrap",
});
