import { Stack, Typography } from "@mui/material";
import { useFireContext } from "../providers";
import { ConstructionPage } from "../components";

export const ProjectPage = () => {
  const { user } = useFireContext();

  return (
    <Stack spacing={1}>
      <Typography variant="h4" fontWeight="bold">
        TVB's website
      </Typography>
      <Typography variant="body1" display={user ? "inline-block" : "none"}>
        {"Signed in as " + user?.displayName}
      </Typography>
      <ConstructionPage />
    </Stack>
  );
};
