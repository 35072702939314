import { ReactElement } from "react";
import { Notepad, notepadInfo } from "./notepad/notepad";
import { SportsScores, sportsScoresInfo } from "./sports-scores/sports-scores";

export interface ProjectInfo {
  path: string;
  name: string;
}

export const projectList: Record<
  string,
  ProjectInfo & { component: () => ReactElement }
> = {
  [notepadInfo.path]: {
    ...notepadInfo,
    component: Notepad,
  },
  [sportsScoresInfo.path]: {
    ...sportsScoresInfo,
    component: SportsScores,
  },
};

export const test = 5;
