import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack, useTheme } from "@mui/material";
import { BorderBox, ImageBox, Info, Navbar } from "./components";
import { Komodo, LA } from "./images";

export const Main = () => {
  const [isWidescreen] = useState<boolean>(false);
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Box
      style={{
        minWidth: "350px",
        backgroundColor: useTheme().palette.background.default,
        height: "100vh",
      }}
    >
      <ImageBox src={isDark ? LA : Komodo} height="100vh">
        <Stack
          maxWidth={isWidescreen ? "xl" : "lg"}
          spacing={1}
          padding={1}
          direction={
            isWidescreen
              ? "column-reverse"
              : { xs: "column-reverse", md: "row" }
          }
        >
          <Stack spacing={1}>
            <BorderBox minWidth="225px">
              <Info />
            </BorderBox>
          </Stack>
          <Stack spacing={1} width="100%">
            <BorderBox>
              <Navbar />
            </BorderBox>
            <BorderBox>
              <Outlet />
            </BorderBox>
          </Stack>
        </Stack>
      </ImageBox>
    </Box>
  );
};
