import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField {...{ ...props, size: "small" }} />
))({
  "& .MuiInputBase-input": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
