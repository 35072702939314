import { ConstructionRounded } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const ConstructionPage = () => {
  return (
    <Stack spacing={1} alignItems="center">
      <ConstructionRounded style={{ fontSize: "100px" }} />
      <Typography variant="body1">Under construction</Typography>
    </Stack>
  );
};
