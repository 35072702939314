import { Box, Container } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";

interface ImageBoxProps {
  src: string;
  isLoadingImg?: boolean;
  height?: string;
  width?: string;
  children?: ReactNode;
}

export const ImageBox = ({
  src,
  isLoadingImg,
  height = undefined,
  width = undefined,
  children,
}: ImageBoxProps) => {
  const isControlled = useMemo(
    () => isLoadingImg !== undefined,
    [isLoadingImg],
  );

  const [isLoadingState, setIsLoadingState] = useState<boolean>(
    !isControlled || Boolean(isLoadingImg),
  );
  const [srcState, setSrcState] = useState<string>(isControlled ? src : "");

  useEffect(() => {
    if (isControlled) {
      setSrcState(src);
      setIsLoadingState(Boolean(isLoadingImg));
    } else {
      setIsLoadingState(true);
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setSrcState(img.src);
        setIsLoadingState(false);
      };
    }
  }, [isLoadingImg, src, isControlled]);

  return (
    <Box style={{ position: "relative" }} height={height} width={width}>
      <Box
        style={{
          backgroundImage: `url(${srcState})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          transition: "opacity 1.5s ease-in-out",
          opacity: isLoadingState ? 0 : 1,
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      />
      <Container disableGutters style={{ position: "relative" }}>
        {children}
      </Container>
    </Box>
  );
};
