import { ProjectInfo } from "../constants";
import { ConstructionPage } from "../../components";

export const sportsScoresInfo: ProjectInfo = {
  path: "sports-scores",
  name: "Sports scores",
};

export const SportsScores = () => {
  return <ConstructionPage />;
};
