import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { Snackbar, Alert } from "@mui/material";

type Severity = "success" | "info" | "warning" | "error";

export interface ToastContextComponents {
  popToast: (message: string, severity: Severity) => void;
  tryToast: <R>(
    funcToTry: () => R,
    message: string,
    severity: Severity,
    defaultReturn?: R,
  ) => R;
  tryToastAsync: <R>(
    funcToTry: () => Promise<R>,
    message: string,
    severity: Severity,
    defaultReturn?: R,
  ) => Promise<R>;
}

const ToastContext = createContext<ToastContextComponents | undefined>(
  undefined,
);

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastState, setToastState] = useState<{
    message: string;
    severity: Severity;
  }>({
    message: "",
    severity: "info",
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popToast = useCallback((message: string, severity: Severity) => {
    setToastState({ message: message, severity: severity });
    setIsOpen(true);
  }, []);

  const tryToast = useCallback(
    <R,>(
      funcToTry: () => R,
      message: string = "Unexpected error",
      severity: Severity = "error",
      defaultReturn?: R,
    ): R => {
      try {
        const out = funcToTry();
        if (out === undefined) {
          throw new Error();
        }
        return out;
      } catch {
        popToast(message, severity);
        return defaultReturn as R;
      }
    },
    [popToast],
  );

  const tryToastAsync = useCallback(
    async <R,>(
      funcToTry: () => Promise<R>,
      message: string = "Unexpected error",
      severity: Severity = "error",
      defaultReturn?: R,
    ): Promise<R> => {
      try {
        const out = await funcToTry();
        if (out === undefined) {
          throw new Error();
        }
        return out;
      } catch {
        popToast(message, severity);
        return defaultReturn as R;
      }
    },
    [popToast],
  );

  return (
    <ToastContext.Provider
      value={{
        popToast: popToast,
        tryToast: tryToast,
        tryToastAsync: tryToastAsync,
      }}
    >
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={toastState.severity}>{toastState.message}</Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
