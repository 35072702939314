import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "./main";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { myTheme } from "./theme";
import { ErrorPage } from "./components";
import { projectList, ProjectPage } from "./projects";
import { FireProvider, ToastProvider } from "./providers";

// router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ProjectPage />,
      },
      ...Object.values(projectList).map((project) => ({
        path: project.path,
        element: <project.component />,
      })),
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
      <FireProvider>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </FireProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
