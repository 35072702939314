import { Box, styled } from "@mui/material";
import { ReactNode } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderWidth: "4px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

export const BorderBox = ({
  children,
  padding = 1,
  minWidth = "",
}: {
  children: ReactNode;
  padding?: number;
  minWidth?: string;
}) => {
  return (
    <StyledBox padding={padding} minWidth={minWidth}>
      {children}
    </StyledBox>
  );
};
