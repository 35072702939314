import {
  Divider,
  IconButton,
  Link,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { BorderBox, ImageBox } from ".";
import { GitHub, Instagram, LinkedIn } from "@mui/icons-material";
import { Chamonix, Virginia } from "../images";

const socials = [
  {
    display: "linkedin.com/in/tevanburen",
    link: "https://www.linkedin.com/in/tevanburen/",
    icon: LinkedIn,
  },
  {
    display: "instagram.com/tevanburen",
    link: "https://www.instagram.com/tevanburen",
    icon: Instagram,
  },
  {
    display: "github.com/tevanburen",
    link: "https://github.com/tevanburen",
    icon: GitHub,
  },
];

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  // borderRightWidth: 5,
}));

export const Info = () => {
  return (
    <Stack alignItems="center" spacing={1}>
      <BorderBox padding={0}>
        <ImageBox
          src={useTheme().palette.mode === "dark" ? Virginia : Chamonix}
          height="100px"
          width="150px"
        />
      </BorderBox>

      <Stack alignItems="center" spacing={1}>
        <Typography
          variant="body1"
          fontWeight="bold"
          whiteSpace="nowrap"
          textAlign="center"
          component="div"
        >
          Thomas Van Buren
          <br />
          <Link
            fontStyle="italic"
            color="inherit"
            underline="hover"
            variant="body2"
            href="mailto:tevanburen@gmail.com"
            onClick={(e) => e.preventDefault()}
          >
            tevanburen@gmail.com
          </Link>
        </Typography>

        <StyledDivider />

        <Stack direction="row">
          {socials.map((social) => (
            <Tooltip
              title={social.display}
              key={social.display}
              placement="bottom-start"
              arrow
            >
              <IconButton
                size="small"
                onClick={() => window.open(social.link, "_blank")}
              >
                <social.icon color="primary" />
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
