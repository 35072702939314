import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import {
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
} from "firebase/firestore/lite";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
// import { getAnalytics } from "firebase/analytics";

export interface FireContextComponents {
  user: User | null;
  signIn: () => void;
  signOut: () => void;
  getMyDoc: (
    collectionName: string,
  ) => DocumentReference<DocumentData, DocumentData> | null;
}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSbUyFGc9aewxMZfl5B1VSkDj19LNh-7U",
  authDomain: "tvbproject-8424d.firebaseapp.com",
  projectId: "tvbproject-8424d",
  storageBucket: "tvbproject-8424d.appspot.com",
  messagingSenderId: "326562826376",
  appId: "1:326562826376:web:4a3c168f757381cb2eb73d",
  measurementId: "G-Y22FY5WSHG",
};
const firebaseApp = initializeApp(firebaseConfig);

// Authentication
const authProvider = new GoogleAuthProvider();
authProvider.setCustomParameters({
  prompt: "select_account ",
});
const auth = getAuth();

// Firestore
const db = getFirestore(firebaseApp);

// const analytics = getAnalytics(app);

// Context components
const FireContext = createContext<FireContextComponents | undefined>(undefined);
const signInWithGooglePopup = () => signInWithPopup(auth, authProvider);
const signOutUser = () => signOut(auth);
const getMyDoc = (collectionName: string) => {
  return auth.currentUser
    ? doc(db, collectionName + "/" + auth.currentUser.uid)
    : null;
};

export const useFireContext = () => {
  const context = useContext(FireContext);
  if (!context) {
    throw new Error("useFireContext must be used within a FireProvider");
  }
  return context;
};

export const FireProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (newUser) => setUser(newUser));
  }, []);

  return (
    <FireContext.Provider
      value={{
        user: user,
        signIn: signInWithGooglePopup,
        signOut: signOutUser,
        getMyDoc: getMyDoc,
      }}
    >
      {children}
    </FireContext.Provider>
  );
};
