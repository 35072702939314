import { Stack, TextField, Typography } from "@mui/material";
import { getDoc, setDoc } from "firebase/firestore/lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckCircleOutlineRounded,
  HistoryRounded,
  SaveRounded,
} from "@mui/icons-material";
import { Button } from "../../components";
import { useFireContext, useToastContext } from "../../providers";
import { ProjectInfo } from "../constants";

export const notepadInfo: ProjectInfo = {
  path: "notepad",
  name: "Notepad",
};

export const Notepad = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [myText, setMyText] = useState<string>("");
  const [loadedText, setLoadedText] = useState<string>("");

  const { user, getMyDoc, signIn } = useFireContext();
  const { popToast, tryToastAsync } = useToastContext();

  // Load data from Firestore into mySavedText
  const fetchUserData = useCallback(async () => {
    setIsFetching(true);

    const newText = await tryToastAsync<string>(
      async () => {
        const myDoc = getMyDoc(notepadInfo.path);
        return myDoc ? (await getDoc(myDoc)).data()?.text : "";
      },
      "Error fetching data",
      "error",
      "",
    );

    setMyText((prev) =>
      Boolean(prev && newText)
        ? prev.localeCompare(newText) === 0
          ? prev
          : newText + "\n" + prev
        : Boolean(newText)
          ? newText
          : prev,
    );
    setLoadedText(newText);
    setIsFetching(false);
  }, [tryToastAsync, getMyDoc]);

  // Write data to Firestore from myNewText
  const writeData = async () => {
    setIsWriting(true);
    try {
      const myDoc = getMyDoc(notepadInfo.path);
      if (!myDoc) throw new Error();
      await setDoc(myDoc, { text: myText });
      setLoadedText(myText);
    } catch {
      setMyText(loadedText);
      popToast("Error writing data", "error");
    }

    setIsWriting(false);
  };

  // Revert data to previous state
  const revertData = async () => {
    setIsWriting(true);
    setMyText(loadedText);
    setIsWriting(false);
  };

  // Gets new text when user changes
  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  const isLoading = useMemo(
    () => isFetching || isWriting,
    [isFetching, isWriting],
  );

  const madeChanges = useMemo(
    () => loadedText.localeCompare(myText),
    [loadedText, myText],
  );

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography variant="h4" fontWeight="bold">
          Notepad
        </Typography>

        <Typography variant="subtitle2">
          Notes you write will be saved for later
        </Typography>
      </Stack>

      <TextField
        disabled={isLoading}
        name="notepad"
        multiline
        value={myText}
        onChange={(e) => setMyText(e.target.value)}
      ></TextField>

      <Stack direction="row" spacing={1}>
        {!user ? (
          <Button
            variant="contained"
            startIcon={<SaveRounded />}
            onClick={signIn}
          >
            Save your notes
          </Button>
        ) : !isLoading && madeChanges ? (
          <>
            <Button
              startIcon={<SaveRounded />}
              onClick={writeData}
              variant="contained"
            >
              Save
            </Button>

            <Button
              startIcon={<HistoryRounded />}
              onClick={revertData}
              variant="outlined"
            >
              Revert
            </Button>
          </>
        ) : (
          <Button
            startIcon={<CheckCircleOutlineRounded />}
            style={{ backgroundColor: "transparent" }}
            disableRipple
            variant="text"
            loading={isLoading}
          >
            Up to date
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
